import { Typography } from '@mui/material';

import Layout from '../components/layout';
import './styles.css';

const Terms = () => {
  return (
    <Layout>
      <div
        style={{
          width: '50%',
        }}
      >
        <Typography
          variant="subtitle1"
          component="p"
          style={{
            textAlign: 'center',
            fontSize: '30px',
            padding: '20px',
          }}
        >
          Terms and Conditions
        </Typography>
        <ol>
          <li>
            <strong>Binding Agreement. </strong> By using the Site or
            participating in the Program, you are bound by these Terms and
            Conditions and indicate your agreement to them. All of the Company’s
            decisions are final and binding.
          </li>
          <li>
            <strong>Privacy.</strong> The personal information collected,
            processed and used as part of the Program will be used in accordance
            with Company’s Privacy Statement, which is available by link on the
            Site.
          </li>
          <li>
            <strong>How the Program Works.</strong> You must be a legal resident
            of the Kingdom of Bahrain, Kingdom of Saudi Arabia, United Arab
            Emirates, the State of Qatar, or the State of Kuwait. You must visit
            the Site and follow the on-screen instructions to refer friends,
            family members or colleagues. Once an individual makes a referral,
            he/she becomes a “Referrer” and will be provided with a unique
            referral link (“Personal Link”) that allows him/her to receive the
            benefit or reward advertised on the Site. Referred friends
            (“Friends”) must complete the referral as described in the referral
            message.
          </li>
          <li>
            <strong>Restrictions.</strong> Referrers cannot refer themselves or
            create multiple, fictitious or fake accounts with the Company or
            participate in the Program as “Friends”. No user may use the Program
            to violate any law, infringe or violate the rights of any third
            party, or otherwise act in a manner that is deemed harassing,
            harmful, illegal, hateful, obscene or outside the spirit and intent
            of the Program. In addition, you may not (i) tamper with the
            Program, (ii) act in an unfair or disruptive manner, or (iii) use
            any system, bot or other device or artifice to participate or
            receive any benefit in the Program. CAUTION: ANY ATTEMPT TO
            DELIBERATELY DAMAGE OR UNDERMINE THE LEGITIMATE OPERATION OF THE
            PROGRAM MAY BE IN VIOLATION OF CRIMINAL AND CIVIL LAWS. SHOULD SUCH
            AN ATTEMPT BE MADE, THE COMPANY RESERVES THE RIGHT TO SEEK REMEDIES
            AND DAMAGES (INCLUDING ATTORNEY FEES) TO THE FULLEST EXTENT OF THE
            LAW, INCLUDING CRIMINAL PROSECUTION.
            <br />
            Employees of the Company or any of its or their subsidiaries,
            affiliates or promotional agencies are not able to participate.
          </li>
          <li>
            <strong>Rewards.</strong> By making a valid referral, subject to
            your location you will receive 10BHD in the Kingdom of Bahrain,
            100SAR in the Kingdom of Saudi Arabia, 100AED in the United Arab
            Emirates, 100QAR in the State of Qatar, 10KD in the State of Kuwait
            and subject to your Friend’s location, they will receive 10BHD in
            the Kingdom of Bahrain, 100SAR in the Kingdom of Saudi Arabia,
            100AED in the United Arab Emirates, 100QAR in the State of Qatar,
            10KD in the State of Kuwait off on their first meal plan. You can
            earn a maximum of XX rewards per year. These rewards may be redeemed
            in various forms at the Company’s sole discretion. Restrictions may
            apply. Rewards are subject to verification. The Company may delay a
            reward for the purposes of investigation. The Company may also
            refuse to verify and process any transaction for any reason.
            <br />
            Unless otherwise stated, rewards have no monetary value and may not
            be redeemed for cash. They are not transferable and may not be
            auctioned, traded, bartered or sold.
          </li>
          <li>
            <strong>Liability.</strong> YOU UNDERSTAND AND AGREE THAT THE
            RELEASED PARTIES (DEFINED BELOW) SHALL NOT BE LIABLE TO YOU FOR ANY
            DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY
            DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS,
            GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF THE COMPANY
            WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES). By participating in
            the Program, you agree to defend, indemnify, release and hold
            harmless Calo Inc., Extole, Inc., as the provider of the referral
            service, and their respective parent companies, affiliates and
            subsidiaries, together with their respective employees, directors,
            officers, licensees, licensors, shareholders, attorneys and agents
            including, without limitation, their respective advertising and
            promotion entities and any person or entity associated with the
            production, operation or administration of the Program
            (collectively, the “Released Parties”), from any and all claims,
            actions, demands, damages, losses, liabilities, costs or expenses
            caused by, arising out of, in connection with, or related to the
            Program (including, without limitation, any property loss, damage,
            personal injury or death caused to any person(s)) and/or the
            awarding, receipt and/or use or misuse of any benefit in the
            Program. To be clear, the Released Parties shall not be liable for
            any technical errors or malfunctions, data theft or corruption, any
            printing or typographical error, or any damage to any computer
            system resulting from participating in, or accessing or downloading
            information in connection with the Program. The Released Parties
            shall not be liable to any users for failure to supply any
            advertised benefit, by reason of any acts of God, any action or
            request by any governmental or quasi-governmental entity (whether or
            not valid), or any other activity or action that is beyond any of
            the Released Parties’ control. TO THE FULLEST EXTENT POSSIBLE BY
            LAW, THE RELEASED PARTIES’ MAXIMUM LIABILITY ARISING OUT OF OR IN
            CONNECTION WITH THE PROGRAM, REGARDLESS OF THE CAUSE OF ACTION
            (WHETHER IN CONTRACT, TORT, BREACH OF WARRANTY, OR OTHERWISE), WILL
            NOT EXCEED $100.
          </li>
          <li>
            <strong>Disclaimer of Warranties.</strong> YOU EXPRESSLY UNDERSTAND
            AND AGREE THAT: (A) THE PROGRAM IS PROVIDED ON AN “AS IS” AND “AS
            AVAILABLE” BASIS AND THE COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES,
            CONDITIONS AND TERMS OF ANY KIND, WHETHER EXPRESS OR IMPLIED BY
            STATUTE, COMMON LAW OR CUSTOM, INCLUDING, BUT NOT LIMITED TO,
            WARRANTIES AS TO PRODUCTS OR SERVICES OFFERED THROUGH THE USE OF THE
            PROGRAM, IMPLIED WARRANTIES OF MERCHANTABILITY, SATISFACTORY
            QUALITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT; (B)
            THE RELEASED PARTIES MAKE AND GIVE NO WARRANTY THAT (i) THE PROGRAM
            WILL MEET YOUR REQUIREMENTS, (ii) THE PROGRAM WILL BE UNINTERRUPTED,
            TIMELY, SECURE, OR ERROR-FREE, AND (iii) THE QUALITY OF ANY
            PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL OBTAINED BY YOU
            THROUGH THE PROGRAM WILL MEET YOUR EXPECTATIONS, AND (C) ANY
            MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE
            PROGRAM IS ACCESSED AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE
            SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE
            DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF ANY
            SUCH MATERIAL.
          </li>
          <li>
            <strong>Bulk Distribution (“Spam”).</strong> Each Referrer is the
            actual sender of the emails and must comply with applicable law.
            Referrals must be created and distributed in a personal manner that
            is appropriate and customary for communications with friends,
            colleagues and family members. By submitting any email address as
            part of the Program, the Referrer represents that he/she has the
            appropriate permission and consent. Bulk email distribution,
            distribution to strangers, or any other use of the services
            described herein in a manner that is not promoted is expressly
            prohibited and may be grounds for immediate termination and further
            legal action. The Company has no obligation to monitor the Program
            or any communications; however, the Company may choose to do so and
            block any email messages, remove any such content, or prohibit any
            use of the Program. Referrers who do not comply with the law,
            including anti-spam laws, are obligated to indemnify the Program
            Entities against any liabilities, costs and expenses it incurs as a
            result of such spam.
          </li>
          <li>
            <strong>Sign-In Credentials.</strong> Users are responsible for
            maintaining the confidentially of any sign-in credentials and are
            fully responsible for all activities that occur through the use of
            them. Users agree to notify the Company immediately if they suspect
            unauthorized access to their account. Users agree that the Company
            will not be liable for any loss or damage arising from unauthorized
            use of their credentials.
          </li>
          <li>
            <strong>Right to Cancel, Modify or Terminate.</strong> We reserve
            the right to cancel, modify or terminate the Program at any time for
            any reason. We reserve the right to disqualify any User at any time
            from participation in the Program if he/she does not comply with any
            of our Terms.
          </li>
        </ol>
      </div>
    </Layout>
  );
};

export default Terms;
