import { Button, Grid, Link, Typography } from '@mui/material';
import { ReferralCouponDetailsRes } from '@calo/app-types';

import { caloTheme } from '../theme/calo';

const CouponDetails = ({
  couponData,
  coupon,
}: {
  couponData: ReferralCouponDetailsRes;
  coupon: string;
}) => {
  const appUrl = new URL(process.env.REACT_APP_APP_LINK!);
  appUrl.searchParams.append('coupon', coupon);
  return (
    <Grid
      container
      direction="column"
      justifyItems="center"
      alignItems="center"
      alignContent="center"
      justifyContent="center"
    >
      <Typography
        variant="h3"
        component="h3"
        style={{
          padding: '20px',
          textAlign: 'center',
          opacity: 1,
          fontWeight: 'bold',
        }}
      >
        You can get upto {couponData.amount}
        {couponData.currency} from {couponData.friendName || 'your friend'}
      </Typography>
      <Typography
        variant="subtitle1"
        component="p"
        style={{
          textAlign: 'center',
          opacity: 1,
        }}
      >
        Join us & claim upto {couponData.amount}
        {couponData.currency} off at checkout on your first meal plan
      </Typography>

      <Link
        href={appUrl.href}
        style={{
          width: '80%',
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <Button
          variant="contained"
          style={{
            background: '#39B776',
            color: caloTheme.palette.white,
            width: '100%',
            padding: '5px',
            margin: '20px',
            fontSize: '20px',
            maxWidth: '600px',
          }}
        >
          CLAIM NOW
        </Button>
      </Link>
    </Grid>
  );
};
export default CouponDetails;
