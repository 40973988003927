import { Grid, ThemeProvider } from '@mui/material';

import { caloTheme } from '../theme/calo';

const Layout = (props: any) => {
  return (
    <ThemeProvider theme={caloTheme}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        columns={12}
        style={{
          padding: '5px',
        }}
      >
        <Grid
          item
          xs={1}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <img
            alt="logo"
            src="logo.png"
            style={{
              height: '130px',
              width: '200px',
              objectFit: 'contain',
            }}
          />
        </Grid>
        {props.children}
        <Grid
          item
          xs={1}
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          style={{
            padding: '20px',
          }}
        >
          ©{new Date().getFullYear()} Calo, All Rights Reserved.{' '}
          <a
            href="/terms"
            style={{
              color: caloTheme.palette.black,
            }}
          >
            Terms and Conditions
          </a>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Layout;
