
import axios from 'axios';
import { stringify } from 'qs';

const createAxios = (baseURL: any) => {
  const newInstance = axios.create({
    baseURL: baseURL,
    headers: {
      'Content-Type': 'application/json'
    },
    paramsSerializer: (params) => stringify(params)
  });

  return newInstance;
};

const client = createAxios(process.env.REACT_APP_REFERRAL_API_URL);

export default client;