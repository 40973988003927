import { CircularProgress, Grid, Typography } from '@mui/material';

const Loader = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography
        variant="h3"
        component="h2"
        style={{
          padding: '20px',
          textAlign: 'center',
          opacity: 1,
        }}
      >
        Retrieving you reward
      </Typography>
      <CircularProgress color="primary" />
    </Grid>
  );
};

export default Loader;
